<template>
  <div class="page">
    <div class="top">
      <headers :headSelectIndex='2'></headers>
      <div class="c_box">
        <div class="con">
          <div class="pics" :class="id==1?'pics':'picss'">
            <div class="p_box">
              <div class="blod" v-if="id==1">图文咨询</div>
              <div class="blod" v-if="id==2">电话咨询</div>
              <div class="desc">{{thisproduct.ProductDescripiton}}</div>
            </div>
          </div>
          <div class="name">选择问题类型</div>
          <div class="tips_box">
            <div class="s_box" v-for="(item,index) in lists" :key="index" @click="choose(index)"
              :class="{'selec':selectIndex==index}">
              <img class="tips" :style="{width:item.wid+'px'}" :src="item.imgSrc" alt="">
              <span>{{item.title}}</span>
              <img class="selected" v-show="selectIndex==index" src="../assets/icon_tick12.png" alt="">
            </div>
          </div>
          <div class="tips_box" style="margin-top:10px">
            <div class="s_box" v-for="(item,index) in list" :key="index" @click="chooseIndex(index)"
              :class="{'selec':selectedIndex==index}">
              <img class="tips" style="width:30px;height:32px;top:-17px" :src="item.imgSrc" alt="">
              <span>{{item.title}}</span>
              <img class="selected" v-show="selectedIndex==index" src="../assets/icon_tick12.png" alt="">
            </div>
          </div>
          <div class="name">请简要描述您的问题</div>
          <div class="content">
            <el-input :rows="5" type="textarea" maxlength="100" ref="focusTextarea" resize="none"
              placeholder="请简要描述您的问题（限10-100字之间）" :show-word-limit="true" v-model="textareaData">
            </el-input>
          </div>
          <div v-if="!isPingan">
            <div class="btn" v-if="id==1&&newUser">
              <!-- 非会员&会员-有新用户首单折扣 -->
              <div class="buy buy_red" @click="goCashier('first')">
                <span>9.9元 立即咨询</span>
                <div class="right_tip">新用户首单特惠</div>
              </div>
              <div class="price">原价：{{thisproduct.ProductPrice}}元</div>
            </div>
            <div class="btns" v-if="id==1&&!newUser && AssociatorState!='已开通'">
              <!-- 非会员-无新用户首单折扣 -->
              <div class="buy_green" style="margin-right:30px" @click="goCashier('default')">
                <span>{{thisproduct.ProductPrice}}元 </span><span>立即咨询</span></div>
              <div class="buy_red" @click="goCashier('open')"><span>0元 </span><span>免费咨询</span>
                <div class="right_tip" style="font-weight:400;right:-9px">开通会员</div>
              </div>
            </div>
            <div class="btn btn1" v-if="id==1&&!newUser&&AssociatorState=='已开通'&&yhList.length>0">
              <!-- 会员-无新用户首单折扣+有可用优惠券 -->
              <div class="buy buy_red" @click="goCashier('yh')">
                <span>{{yhPrice}} 立即咨询</span>
                <div class="right_tip" style="right:-12px">券后价</div>
              </div>
              <div class="price">原价：{{thisproduct.ProductPrice}}元</div>
            </div>
            <div class="btn" v-if="id==1&&!newUser&&AssociatorState=='已开通'&&yhList.length==0">
              <!-- 会员-无新用户首单折扣+无可用优惠券 -->
              <div class="buy buy_red" @click="goCashier('default')">
                <span>{{thisproduct.ProductPrice}} 立即咨询</span>
              </div>
            </div>
            <div class="btns" v-if="id==2&& AssociatorState!='已开通'">
              <!-- 电话 非会员 -->
              <div class="buy_green" style="margin-right:30px" @click="goCashier('default')">
                <span>{{thisproduct.ProductPrice}}元 </span><span>立即咨询</span></div>
              <div class="buy_red" @click="goCashier('open')"><span>30元 </span><span>特价咨询</span>
                <div class="right_tip" style="font-weight:400;right:-9px">开通会员</div>
              </div>
            </div>
            <div class="btn" v-if="id==2&&AssociatorState=='已开通'&&yhList.length>0">
              <!-- 会员-有可用优惠券 -->
              <div class="buy buy_red" @click="goCashier('yh')">
                <span>{{yhPrice}} 立即咨询</span>
                <div class="right_tip" style="right:-12px">券后价</div>
              </div>
              <div class="price">原价：{{thisproduct.ProductPrice}}元</div>
            </div>
            <div class="btn" v-if="id==2&&AssociatorState=='已开通'&&yhList.length==0">
              <!-- 会员-无可用优惠券 -->
              <div class="buy buy_red" @click="goCashier('default')">
                <span>{{thisproduct.ProductPrice}} 立即咨询</span>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="btn">
              <div class="buy buy_red" @click="goCashier('default')">
                <span>0元 免费咨询</span>
              </div>
              <div class="price">原价：{{thisproduct.ProductPrice}}元</div>
            </div>
          </div>
        </div>
        <div class="advers">
          <div class="vips" v-if="!newUser&&AssociatorState!='已开通'">
            <img src="../assets/img_photo_vip.png" alt="" @click="buyMember">
          </div>
          <div class="hot">
            <img class="icon" src="../assets/icon_tuijian15.png" alt="">
            <img v-if="id==1" class="h_buy" src="../assets/img_phone_ad.png" alt="" @click="change(2)">
            <img v-if="id==2" class="h_buy" src="../assets/img_photo_ad.png" alt="" @click="change(1)">
          </div>
        </div>
      </div>
    </div>
    <sideBar></sideBar>
    <FootBox :style="{marginTop:cHeight+'px'}" v-if="cHeight"></FootBox>
  </div>
</template>

<script>
  import FootBox from '@/components/FootBox'
  import sideBar from '@/components/sideBar'
  import headers from '@/components/header'
  import {
    IsNewUser,
    QuickTextOrder,
    GetCashCouponsList,
    GetConsultationProduct,
    QuickTelOrder,
    QuickAssociatorOrder,
    GetMemberAssociatorPCList
  } from '@/api/index'
  export default {
    data() {
      return {
        cHeight: null,
        selectIndex: null,
        selectedIndex: null,
        lists: [{
          imgSrc: require('../assets/icon_hunyin31.png'),
          title: '家庭婚姻',
          wid: 33,
          id: '0AF2570F-E6D8-4D2D-A5B3-B920E95B41FA'
        }, {
          imgSrc: require('../assets/icon_tudi31.png'),
          title: '土地房产',
          wid: 36,
          id: '48DCBE38-42A5-4A99-ADFA-2FF108E9679C'
        }, {
          imgSrc: require('../assets/icon_jiaotgng31.png'),
          title: '交通事故',
          wid: 29,
          id: 'C28158A6-9868-4AE3-840F-6C80ECC8996B'
        }, {
          imgSrc: require('../assets/icon_hetong.png'),
          title: '合同欠款',
          wid: 24,
          id: '982576B0-EDD9-460E-AAC2-07259D7560ED'
        }, {
          imgSrc: require('../assets/icon_laodong31.png'),
          title: '劳动维权',
          wid: 27,
          id: 'AF55723B-029C-49C0-8690-EB7FA0AEECF7'
        }],
        list: [{
          imgSrc: require('../assets/icon_gognsi31.png'),
          title: '企业创业',
          id: '64CADB04-014F-4A87-AC22-0B5DCD7EA911'
        }, {
          imgSrc: require('../assets/icon_xingshi31.png'),
          title: '刑事法律',
          id: 'A3E12597-0D09-4F8D-AB32-EBC34AC89B9B'
        }, {
          imgSrc: require('../assets/icon_knowledge.png'),
          title: '知识产权',
          id: '58EC9AB8-EF79-4033-B15D-F7C1BB85404B'
        }, {
          imgSrc: require('../assets/icon_jiedai31.png'),
          title: '债权债务',
          id: '1691B9EA-6F1B-4192-BFFC-E6D1B6D9F01D'
        }, {
          imgSrc: require('../assets/icon_other.png'),
          title: '其他法律',
          id: 'C05FE8AA-00BB-402A-97AF-0ED18F7D069A'
        }],
        textareaData: '',
        focus: false,
        id: 1,
        user: null,
        newUser: false,
        AssociatorState: '',
        yhList: [],
        product: [],
        thisproduct: {
          ProductDescripiton: ''
        },
        yhPrice: 0,
        member1: '',
        isPingan:null
      }
    },
    mounted() {
      _hmt.push(['_trackPageview', '/ask'])
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) - 590
      if (this.cHeight < 490) {
        this.cHeight = 490
      }
      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      this.AssociatorState = this.user.AssociatorState
      this.id = this.$route.query.id
      this.isNewUser()
      this.getProduct()
      this.isMember().then(res => {
        this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        this.AssociatorState = this.user.AssociatorState
      })
      this.checkPingan().then(res=>{
        if(res==0){
          this.isPingan=true
        }else{
          this.isPingan=false
        }
      })
    },

    methods: {
      goCashier(type) {
        _hmt.push(['_trackEvent', 'click', '咨询提交按钮', 'pc']);
        if (this.selectIndex == null && this.selectedIndex == null) {
          this.$message('请选择问题类型')
        } else if (this.textareaData.length < 10) {
          this.$message('请简要描述您的问题（限10-100字之间）')
        } else {
          var questionType
          if (this.selectIndex != null) {
            questionType = this.lists[this.selectIndex].id
          } else {
            questionType = this.list[this.selectedIndex].id
          }
          if (this.id == 1) {
            var data = {
              "questionType": questionType,
              "description": this.textareaData,
              "memberGuid": this.user.MemberGuid,
              "orderFrom": localStorage.getItem('orderfrom'),
              "isFreeOrder": 0,
            }
            var loading = this.$loading();
            QuickTextOrder(data).then(res => {
              console.log(res)
              loading.close();
              if (res.state == 0) {
                localStorage.setItem('payOrder', JSON.stringify(res.data))
                if (res.data.OrderNeedPay == 0) {
                  this.$store.dispatch('onChatType', 'wait')
                  localStorage.setItem('chatroomLawyer', '')
                  this.$router.replace('/chatroom')
                } else {
                  if (type == 'first') {
                    this.$router.push('/cashier?first=1&from=ask')
                  } else if (type == 'open') {
                    this.$router.push('/cashier?member=1&from=ask')
                  } else {
                    this.$router.push('/cashier?from=ask')
                  }
                }
              } else {
                this.$message(res.des)
              }
            })
          } else {
            var data = {
              "questionType": questionType,
              "description": this.textareaData,
              "memberGuid": this.user.MemberGuid,
              "orderFrom": localStorage.getItem('orderfrom'),
              "isFreeOrder": 0,
            }
            var loading = this.$loading();
            QuickTelOrder(data).then(res => {
              loading.close();
              if (res.state == 0) {
                localStorage.setItem('payOrder', JSON.stringify(res.data))
                if (res.data.OrderNeedPay == 0) {
                  this.$store.dispatch('onChatType', 'wait')
                  localStorage.setItem('chatroomLawyer', '')
                  this.$router.replace('/chatroom')
                } else{
                  if (type == 'open') {
                    this.$router.push('/cashier?member=1&from=ask')
                  } else {
                    this.$router.push('/cashier?from=ask')
                  }
                }
                
              } else {
                this.$message(res.des)
              }
            })
          }

        }
      },
      buyMember() {
        _hmt.push(['_trackEvent', 'click', '会员营销', 'pc']);
        var data = {
          "productGuid": this.member1.ProductGuid,
          "memberGuid": this.user.MemberGuid,
          "orderFrom": localStorage.getItem('orderfrom'),
        }
        var loading = this.$loading();
        QuickAssociatorOrder(data).then(res => {
          loading.close();
          if (res.state == 0) {
            localStorage.setItem('payOrder', JSON.stringify(res.data))
            this.$router.push('/cashier?yhMember=1')
          } else {
            this.$message(res.des)
          }
        })
      },
      getProduct() {
        GetConsultationProduct().then(res => {
          if (res.state == 0) {
            this.product = res.data
            for(var i=0;i<res.data.length;i++){
              if(this.id==1&&res.data[i].ProductTitle=='文字咨询'){
                this.thisproduct =res.data[i]
              }else if(this.id==2&&res.data[i].ProductTitle=='电话咨询'){
                this.thisproduct =res.data[i]
              }
            }
            this.getYH()
          }
        })
      },
      getYH() {
        var data = {
          "memberGuid": this.user.MemberGuid,
          "filter": '可使用',
          "type": this.id == 1 ? '文字' : '电话',
          "direction": 0
        }
        GetCashCouponsList(data).then(res => {
          if (res.state == 0) {
          console.log(res)
            if (res.data.length > 0) {
              this.yhList = res.data
              var p = (this.thisproduct.ProductPrice * 1000 - res.data[0].Price * 1000) /1000
              this.yhPrice = p > 0 ? p : 0
            } else {
              this.yhList = []
              this.yhPrice = 0
            }
          }else{
            this.$message(res.des)
          }
        })
      },
      change(num) {
        _hmt.push(['_trackEvent', 'click', '热门推荐', 'pc']);
        this.id = num
        this.textareaData=''
        this.$router.replace({
          query: {
            'id': num
          }
        })
        this.getProduct()
      },
      choose(e) {
        this.selectedIndex = null
        this.selectIndex = e
      },
      chooseIndex(e) {
        this.selectIndex = null
        this.selectedIndex = e
      },
      isNewUser() {
        let data = {
          userID: this.user.MemberGuid,
          tabName: ''
        }
        IsNewUser(data).then(res => {
          if (res.state == 0) {
            if (res.data == '不是新用户') {
              this.newUser = false
            } else {
              this.newUser = true
            }
            if (!this.newUser && this.AssociatorState != '已开通') {
              GetMemberAssociatorPCList({
                "memberGuid": this.user.MemberGuid
              }).then(res => {
                if (res.state == 0) {
                  for (var i = 0; i < res.data.length; i++) {
                    if (res.data[i].ProductTitle == '1个月会员') {
                      this.member1 = res.data[i]
                    }
                  }
                }
              })
            }
          }else{
            this.$message(res.des)
          }
        })
      }
    },
    watch: {
      '$route.query.id': {
        handler(newVal, oldVal) {
          this.id = newVal
        },
        deep: true
      },
      id(newVal, oldVal) {
        if (this.product.length > 0) {
          this.thisproduct = this.product[newVal - 1]
        }
      }
    },
    components: {
      FootBox,
      sideBar,
      headers
    }
  }

</script>

<style scoped lang="scss">
  .page {
    background: #F3F3F3;
  }

  .top {
    height: 410px;
    background: linear-gradient(180deg, #21D0C8 0%, #20C0F5 60%, #F3F3F3 85%);
  }

  .c_box {
    display: flex;
    justify-content: space-between;
    width: 940px;
    margin: auto;
  }

  .con {
    width: 620px;
    height: 720px;
    background: #FFFFFF;
    border-radius: 20px;

  }

  .advers {
    width: 300px;
  }

  .pics {
    margin: 20px;
    width: 580px;
    height: 140px;
    background: url('../assets/img_photo140.png');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .p_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .blod {
    padding: 0 0 7px 28px;
    font-size: 26px;
    font-weight: bold;
    color: #1A1A1A;
  }

  .desc {
    margin-left: 26px;
    width: 320px;
    font-size: 12px;
    color: #666666;
  }

  .name {
    margin: 30px 20px 0;
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .tips_box {
    margin: 20px 20px 20px;
    height: 65px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .s_box {
    position: relative;
    width: 100px;
    height: 50px;
    line-height: 54px;
    background: url('../assets/type_bg_normal.png');
    background-size: 100%;
    background-repeat: no-repeat;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 2px 8px 0px rgba(0, 200, 180, 0.1000);
    transition: margin 0.5s;
    -webkit-transition: margin 0.5s;

    span {
      margin-left: 12px;
      color: #333;
      font-size: 15px;
    }
  }

  .tips {
    position: absolute;
    right: 0;
    top: -15px;
    height: 31px;

  }

  .s_box:hover {
    margin-bottom: 10px;
    background: url('../assets/type_bg_selected.png');
    box-shadow: 0px 4px 12px 0px rgba(0, 200, 180, 0.1400);
  }

  .selec {
    margin-bottom: 10px;
    background: url('../assets/type_bg_selected.png');
    box-shadow: 0px 4px 12px 0px rgba(0, 200, 180, 0.1400);
  }

  .selected {
    position: absolute;
    right: 4px;
    bottom: 4px;
    width: 16px;
    height: 16px;
  }

  .content {
    position: relative;
    margin: 20px;
    height: 100px;

    textarea {
      padding: 15px;
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: #000000;
      border: 1px solid #E8E8E8 !important;
      border-radius: 6px;
      outline: none;
      box-sizing: border-box;
      background: #F5F5F8 !important;
      resize: none;
    }

    .num {
      position: absolute;
      right: 14px;
      bottom: 8px;
      font-size: 12px;
      color: #AAAAAA;
    }
  }

  .btn {
    margin-top: 50px;
    text-align: center;
    cursor: default;
  }

  .buy {
    margin: auto;
    position: relative;
    width: 300px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: #F24537;
    border-radius: 25px;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
  }

  .buy_green {
    background: #00C8B4;
  }

  .buy_green:hover {
    background: #33D3C3;
  }

  .buy_red {
    background: #F24537;
  }

  .buy_red:hover {
    background: #F46155;
  }

  .price {
    margin-top: 15px;
    font-size: 16px;
    color: #AAAAAA;
    text-align: center;
    cursor: default;
  }

  .right_tip {
    position: absolute;
    height: 24px;
    line-height: 24px;
    padding: 0 5px;
    right: -38px;
    top: -9px;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background: linear-gradient(0deg, #D19961 0%, #EEBF7D 100%);
    border-radius: 10px 0px 10px 0px;
  }

  .btn1 .right_tip {
    right: -12px;
  }

  .btns {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    cursor: default;

    >div {
      position: relative;
      width: 240px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
      font-weight: bold;
      color: #FFFFFF;
      border-radius: 25px;
    }
  }

  .vips {
    margin-bottom: 20px;
    width: 300px;
    height: 380px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .hot {
    padding: 20px;
    width: 300px;
    height: 320px;
    background: #FFFFFF;
    border-radius: 20px;
    box-sizing: border-box;

    .icon {
      margin-bottom: 10px;
      width: 80px;
      height: 19px;
    }

    .h_buy {
      width: 260px;
      height: 250px;
      cursor: pointer;
    }


  }

  .picss {
    margin: 20px;
    width: 580px;
    height: 140px;
    background: url('../assets/img_phone140.png');
    background-size: 100%;
    background-repeat: no-repeat;
  }

</style>
<style lang="scss">
  .content {
    .el-textarea__inner {
      padding: 15px;
      width: 100%;
      height: 100px;
      font-size: 14px;
      color: #000000;
      border: 1px solid #E8E8E8;
      border-radius: 6px;
      outline: none;
      box-sizing: border-box;
      background: #F5F5F8;
      resize: none;
    }

    .el-textarea__inner:focus {
      background: #fff;
    }

    .el-textarea .el-input__count {
      background: inherit;
    }
  }

</style>
